export default [
    {
        path: "/admin/toolbox/toolbox",
        name: "toolbox-toolbox",
        component: () => import("@/views/toolbox/toolbox.vue"),
    },
    {
        path: "/admin/toolbox/job",
        name: "toolbox-job",
        component: () => import("@/views/toolbox/job.vue"),
    },
    {
        path: "/admin/toolbox/requestlog",
        name: "requestlog-job",
        component: () => import("@/views/toolbox/requestlog.vue"),
    },
]