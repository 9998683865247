<template>
  <div class="d-flex flex-column">
    <el-upload action="#"
               ref="uploadImageRef"
               class="zhi-image-upload"
               :limit="1"
               :on-exceed="handleUploadExceed"
               :on-change="handleUploadChange"
               :show-file-list="false"
               :style="{width: uploadOptions.imageWidth, height: uploadOptions.imageHeight}"
               :auto-upload="false">
      <div class="upload-image"
           :style="{backgroundImage: `url(${uploadForm.imageSrc})`}"
           v-if="uploadForm.imageSrc">
      </div>
      <div class="upload-remove" v-if="uploadForm.imageFile" @click="handleUploadRemove">
        <i class="fal fa-close"></i>
      </div>
      <template #trigger>
        <div class="upload-btn">
          <i class="fal fa-plus"></i>
        </div>
      </template>
    </el-upload>
    <span class="text-muted fs-8">{{ uploadOptions.uploadInfo }}</span>
  </div>
  <el-dialog
      v-model="uploadForm.cropperDialogVisible"
      :close-on-click-modal="false"
      title="剪切图片"
      width="950px"
      top="50px"
      @close="uploadForm.cropperDialogVisible = false"
  >
    <div class="d-flex">
      <div :style="{width: uploadForm.cropWidth, height: uploadForm.cropHeight}">
        <vue-cropper
            ref="imageCropper"
            :img="uploadForm.imageCropperUrl"
            outputType="jpg"
            :autoCrop="true"
            :autoCropWidth="uploadOptions.autoCropWidth"
            :autoCropHeight="uploadOptions.autoCropHeight"
            :fixedBox="false"
            :fixed="true"
            :fixedNumber="uploadOptions.cropFixedNumber"
            :centerBox="false"
            :infoTrue="true"
            :original="false"
            :high="false"
            @realTime="cropperPreview"
        />
      </div>
      <div class="ms-6">
        <div class="fs-6 fw-bolder">预览图片</div>
        <div class="show-preview mt-2" style="border: 1px solid #666;" :style="{'width': uploadForm.imageCropperPreview.w + 'px', 'height': uploadForm.imageCropperPreview.h + 'px',  'overflow': 'hidden', 'zoom': 0.5}">
          <div :style="uploadForm.imageCropperPreview.div">
            <img :src="uploadForm.imageCropperPreview.url" :style="uploadForm.imageCropperPreview.img">
          </div>
        </div>
      </div>
    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="cropperConfirm">
              <i class="fal fa-check me-2"></i>
              确认
          </el-button>
          <el-button class="ms-2" @click="uploadForm.cropperDialogVisible = false">
              <i class="fal fa-times-circle me-2"></i>
              关闭
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>

import {ElMessage, genFileId} from "element-plus";
import { VueCropper }  from "vue-cropper";

export default {
  components: {
    VueCropper
  },
  props: {
    uploadOptions: {
      type: Object,
      default: () => ({
        defaultSrc: '',
        uploadInfo: '列表页图片300x300',
        imageWidth: '120px',
        imageHeight: '120px',
        realWidth: '',
        realHeight: '',
        isCropper: true,
        isLimit: false,
        limitWidth: 100,
        limitHeight: 100,
        autoCropWidth: '',
        autoCropHeight: '',
        cropFixedNumber: [1,1]
      })
    }
  },
  emits: ['update-data','revoke-data'],
  data() {
    return {
      uploadForm: {
        cropWidth: '550px',
        cropHeight: '550px',
        imageSrc: '',
        imageFile: '',
        imageCropperUrl: '',
        imageRemove: false,
        cropperDialogVisible: false,
        imageCropperPreview: ''
      },
    }
  },
  mounted:function(){
    if(!this.uploadOptions.autoCropWidth){
      this.uploadOptions.autoCropWidth = this.uploadOptions.imageWidth;
      this.uploadOptions.autoCropHeight = this.uploadOptions.imageHeight;
    }
    this.uploadOptions.cropFixedNumber = [parseInt(this.uploadOptions.autoCropWidth), parseInt(this.uploadOptions.autoCropHeight)]
  },
  computed: {
    defaultSrc(){
      return this.uploadOptions.defaultSrc;
    }
  },
  methods: {
    initUpload: function(){
      this.uploadForm.imageSrc = this.defaultSrc;
      this.uploadForm.imageFile = '';
      this.uploadForm.uploadRemove = false;
      this.uploadForm.cropperDialogVisible = false;
    },
    handleUploadChange : function(files) {
      let self = this;
      let reader = new FileReader();
      reader.onload = function(e) {
        self.uploadForm.imageCropperUrl = e.target.result;
        if(self.uploadOptions.isCropper){
          let img = new Image();
          img.onload = function() {
            self.uploadOptions.realWidth = this.width;
            self.uploadOptions.realHeight = this.height;
            if(false && !self.uploadOptions.autoCropWidth){
              let w = 0, h = 0;
              if(this.width/this.height > 550/550){
                w = 550;
                h = (550*this.height)/this.width;
              }else{
                h = 550;
                w = (550*this.width)/this.height;
              }
              let bw = parseInt(self.uploadOptions.imageWidth);
              let bh = parseInt(self.uploadOptions.imageHeight);
              let nw = 0, nh = 0;
              if(w/h > bw/bh){
                nh = h;
                nw = (h*bw)/bh;
              }else{
                nw = w;
                nh = (w*bh)/bw;
              }
              self.uploadOptions.autoCropWidth = nw+'px';
              self.uploadOptions.autoCropHeight = nh+'px';
            }

            self.uploadForm.cropperDialogVisible = true;
          }
          img.src = e.target.result;
        }else if(self.uploadOptions.isLimit){
          let img = new Image();
          img.onload = function(){
            let width = this.width;
            let height = this.height;
            if(width == self.uploadOptions.width && height == self.uploadOptions.height){
              self.uploadForm.imageSrc = e.target.result;
              self.uploadForm.imageFile = files.raw;
              self.uploadForm.imageRemove = true;
              self.$emit('update-data', {
                imageSrc: self.uploadForm.imageSrc,
                imageFile: self.uploadForm.imageFile
              });
            }else{
              ElMessage({
                showClose: true,
                type: 'error',
                message: "图片尺寸不是"+self.uploadOptions.width+"x"+self.uploadOptions.height+"！",
              });
            }
          }
          img.src = files.raw;
        }else{
          self.uploadForm.imageSrc = e.target.result;
          self.uploadForm.imageFile = files.raw;
          self.uploadForm.imageRemove = true;
          self.$emit('update-data', {
            imageSrc: self.uploadForm.imageSrc,
            imageFile: self.uploadForm.files
          });
        }
      }
      reader.readAsDataURL(files.raw);
    },
    handleUploadExceed : function(files) {
      this.$refs.uploadImageRef.clearFiles()
      const file = files[0]
      file.uid = genFileId()
      this.$refs.uploadImageRef.handleStart(file)
    },
    handleUploadRemove : function(){
      this.uploadForm.imageFile = '';
      this.uploadForm.imageSrc = this.defaultSrc;
      this.uploadForm.imageRemove = false;
      this.$emit('revoke-data');
    },
    cropperPreview : function(data) {
      this.uploadForm.imageCropperPreview = data;
    },
    cropperConfirm : function(){
      let self = this;
      this.$refs.imageCropper.getCropData(data => {
        self.uploadForm.imageSrc = data;
        self.uploadForm.imageRemove = true;
        self.uploadForm.cropperDialogVisible = false;
        self.$refs.imageCropper.getCropBlob(data => {
          self.uploadForm.imageFile = data;
          self.$emit('update-data', {
            imageSrc: self.uploadForm.imageSrc,
            imageFile: self.uploadForm.imageFile
          });
        })
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.zhi-image-upload{
  width: 120px;
  height: 120px;
  border: 1px solid #e1e1e1;
  position: relative;
  .upload-image{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .upload-btn{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    i{
      font-size: 32px;
      color: #a1a1a1;
    }
  }
  .upload-remove{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: flex-end;
    background-color: #000000;
    border-bottom-left-radius: 15px;
    z-index: 3;
    i{
      margin-right: 2px;
      font-size: 12px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
