export default [
    {
        path: "/admin/agent/list",
        name: "agent-list",
        component: () => import("@/views/agent/list.vue"),
    },
    {
        path: "/admin/agent/create",
        name: "agent-create",
        component: () => import("@/views/agent/create.vue"),
    },
]