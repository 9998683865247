import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import ZhiService from "@/core/services/ZhiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

const state = () => {
  return {
    errors: {},
    user: {
      name: '',
      nickname: '',
      type: 2,
      api_token: '',
      resourceList: []
    },
    isAuthenticated: !!JwtService.getToken()
  };
};

// getters
const getters = {
  currentUser: (state) => {
    return state.user
  },
  isUserAuthenticated: (state) => {
    return state.isAuthenticated
  },
  getErrors: (state) => {
    return state.errors
  },
};

// actions
const actions = {
  [Actions.LOGIN]({ commit }, credentials) {
    ApiService.setHeader();
    return ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          commit(Mutations.SET_ERROR, response.data.errors);
        });
  },
  [Actions.LOGOUT]({ commit }) {
    commit(Mutations.PURGE_AUTH);
  },
  [Actions.REGISTER]({ commit }, credentials) {
    return ApiService.post("register", credentials)
        .then(({ data }) => {
          commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          commit(Mutations.SET_ERROR, response.data.errors);
        });
  },
  [Actions.FORGOT_PASSWORD]({ commit }, payload) {
    return ApiService.post("forgot_password", payload)
        .then(() => {
          commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          commit(Mutations.SET_ERROR, response.data.errors);
        });
  },
  [Actions.VERIFY_AUTH]({ commit }, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("auth/verify", payload)
          .then(({ data }) => {
            commit(Mutations.SET_AUTH, data);
          })
          .catch(({ response }) => {
            commit(Mutations.SET_ERROR, response.data.errors);
            commit(Mutations.PURGE_AUTH);
          });
    } else {
      commit(Mutations.PURGE_AUTH);
    }
  },
};

// mutations
const mutations = {
  [Mutations.SET_ERROR](state, { error }) {
    state.errors = { ...error };
  },
  [Mutations.SET_AUTH](state, data) {
    state.isAuthenticated = true;
    let accountInfo = {
      name: data.name,
      nickname: data.nickname,
      type: data.type,
      avatarNumber: data.avatarNumber
    };
    state.user = accountInfo;
    state.errors = {};
    ZhiService.saveAccountInfo(accountInfo);
    JwtService.saveToken(data.api_token);
  },
  [Mutations.SET_USER](state, user) {
    state.user = user;
  },
  [Mutations.SET_PASSWORD](state, { password }) {
    state.user.password = password;
  },
  [Mutations.SET_AVATAR_NUMBER](state, payload) {
    let accountInfo = state.user;
    accountInfo['avatarNumber'] = payload;
    ZhiService.saveAccountInfo(accountInfo);
  },
  [Mutations.SET_ACCOUNT_RESOURCE](state, payload) {
    state.user.resourceList = payload ? payload : [];
  },
  [Mutations.PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = [];
    JwtService.destroyToken();
    ZhiService.destroyAccountInfo();
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
};
