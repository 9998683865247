<template>
  <router-view />
</template>

<script setup>
import {onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import ZhiService, {getUuid} from "@/core/services/ZhiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import request from "sync-request";
import JwtService from "@/core/services/JwtService";
import axios from "axios";

const store = useStore();
const router = useRouter();

const verify = () => {
  let res = request('POST', process.env.VUE_APP_API_URL+'/admin/auth/verify', {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': JwtService.getToken(),
      'zhi-uuid': getUuid()
    },
  });
  if(res.statusCode == 200){
    let data = JSON.parse(res.body)
    if(data['flag'] && data['flag'] == 'yes'){
      store.commit(Mutations.SET_USER, ZhiService.getAccountInfo());
      ZhiService.initAccountInfo();
    }else{
      store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "sign-in" }));
    }
  }else{
    store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "sign-in" }));
  }
}
onMounted(() => {
  console.log('begin to auth verify');
  verify()
});
</script>

<style lang="scss">
@import "~sweetalert2/dist/sweetalert2.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~element-plus/dist/index.css";
@import "~viewerjs/dist/viewer.css";
@import '~vue-cropper/dist/index.css';

@import "assets/fontawesome/css/all.css";
@import "assets/sass/zhi/theme.scss";
@import "assets/sass/zhi/common.scss";
@import "assets/sass/zhi/admin.scss";

body{
  margin: 0px;
  padding: 0px;
}
</style>
