import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {routeMap} from "@/core/data/ConstData";

const state = () => {
  return {
    breadcrumbs: [],
    breadcrumbShow: true,
    currentPath: '',
    asideMenu: [],
    asideMenuActiveIndex: 0,
    asideMinimize: false,
    routeMap: {},
    subMenuMap: {},
    subMenu: [],
    module: 'admin',
    currentRoute: '/admin',
    contentHeight: 500,
    tableHeight: 500
  };
};

// getters
const getters = {
  breadcrumbs: (state) => {
    return state.breadcrumbs
  },
  breadcrumbShow: (state) => {
    return state.breadcrumbShow
  },
  currentPath: (state) => {
    return state.currentPath
  },
  asideMinimize: (state) => {
    return state.asideMinimize
  },
  asideMenu: (state) => {
    return state.asideMenu
  },
  asideMenuActiveIndex: (state) => {
    return state.asideMenuActiveIndex
  },
  routeMap: (state) => {
    return state.routeMap
  },
  subMenu: (state) => {
    return state.subMenu
  },
  subMenuMap: (state) => {
    return state.subMenuMap
  },
  currentRoute: (state) => {
    return state.currentRoute
  },
  module: (state) => {
    return state.module
  },
  tableHeight: (state) => {
    return state.tableHeight
  },
  contentHeight: (state) => {
    return state.contentHeight
  },
};

// actions
const actions = {
  [Actions.ADD_BODY_CLASSNAME]({ commit }, className) {
    document.body.classList.add(className);
  },
  [Actions.REMOVE_BODY_CLASSNAME]({ commit }, className) {
    document.body.classList.remove(className);
  },
  [Actions.SET_MENU_ASIDE_MINIMIZE]({ commit, state }, payload) {
    if(payload){
      document.body.classList.add('aside-minimize');
      state.asideMinimize = true;
    }else{
      document.body.classList.remove('aside-minimize');
      state.asideMinimize = false;
    }
  },
  [Actions.GET_SUB_MENU]({ commit, state }, payload) {
    if(state.subMenuMap && state.subMenuMap[payload]){
      commit(Mutations.SET_SUB_MENU, state.subMenuMap[payload]);
    }
  },
  [Actions.CAL_CONTAINER_HEIGHT]({ commit, state }) {
    state.containerHeight = document.documentElement.clientHeight - 125;
    // let h = document.documentElement.clientHeight - 60 - 15 * 2 - 48 - 55 - 13*2 - 50 - 28 - 6.5;
    let h = document.documentElement.clientHeight - 230;
    state.tableHeight = h > 500 ? h - 3 : 500;
    // console.log('container height: '+state.containerHeight+' table height: '+state.tableHeight)
  },
};

// mutations
const mutations = {
  [Mutations.SET_BREADCRUMB](state, payload) {
    state.breadcrumbs = payload;
  },
  [Mutations.SET_BREADCRUMB_SHOW](state, payload) {
    state.breadcrumbShow = payload;
  },
  [Mutations.SET_CURRENT_PATH](state, payload) {
    state.currentPath = payload;
  },
  [Mutations.SET_ACCOUNT_MENU](state, payload) {
    if(payload['sideMenu']['admin']){
      state.asideMenu = payload['sideMenu']['admin'];
      const m = routeMap[window.location.pathname]
      if(m){
        state.asideMenu.forEach((m1) => {
          if(m1['code'] == m){
            state.asideMenuActiveIndex = String(m1['id'])
          }else if(m1['list']){
            m1['list'].forEach((m2) => {
              if(m2['code'] == m){
                state.asideMenuActiveIndex = String(m2['id'])
              }else if(m2['list']){
                m2['list'].forEach((m3) => {
                  if(m3['code'] == m){
                    state.asideMenuActiveIndex = String(m3['id'])
                  }
                })
              }
            })
          }
        })
      }else{
        if(payload['sideMenu']['admin'].length > 0){
          state.asideMenuActiveIndex = String(payload['sideMenu']['admin'][0]['id'])
        }
      }
    }
    if(payload['subMenuMap']['admin']){
      state.subMenuMap = payload['subMenuMap']['admin'];
    }
  },
  // [Mutations.SET_ASIDE_MENU_ACTIVE_INDEX1](state, payload) {
  //   state.asideMenuActiveIndex = String(payload)
  // },
  [Mutations.SET_ASIDE_MENU_ACTIVE_INDEX](state, payload) {
    state.asideMenu.forEach((m1) => {
      if(m1['code'] == payload){
        state.asideMenuActiveIndex = String(m1['id'])
      }else if(m1['list']){
        m1['list'].forEach((m2) => {
          if(m2['code'] == payload){
            state.asideMenuActiveIndex = String(m2['id'])
          }else if(m2['list']){
            m2['list'].forEach((m3) => {
              if(m3['code'] == payload){
                state.asideMenuActiveIndex = String(m3['id'])
              }
            })
          }
        })
      }
    })
  },
  [Mutations.SET_ASIDE_MINIMIZE](state, payload) {
    state.asideMinimize = payload;
  },
  [Mutations.SET_MODULE](state, payload) {
    state.module = payload;
  },
  [Mutations.SET_TOP_MENU](state, payload) {
    state.topMenu = payload;
  },
  [Mutations.SET_SUB_MENU](state, payload) {
    state.subMenu = payload;
  },
  [Mutations.SET_CURRENT_ROUTE](state, payload) {
    state.currentRoute = payload;
  },
  [Mutations.SET_ASIDE_MINIMIZE](state, payload) {
    state.asideMinimize = payload;
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
};
