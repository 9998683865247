import { v4 as uuidv4 } from 'uuid';
import store from "@/store/index";
import request from "sync-request";
import JwtService from "@/core/services/JwtService";
import useClipboard from 'vue-clipboard3'
import XLSX from "xlsx-js-style";
import {Mutations} from "@/store/enums/StoreEnums";
import {ElLoading} from "element-plus";
import router from "@/router/index";
import ApiService from "@/core/services/ApiService";
import MarkdownIt from 'markdown-it'

const UUID_KEY = "zhi-uuid";

export const checkAndGenerateUuid = () => {
    let uuid = window.localStorage.getItem(UUID_KEY);
    if(!uuid){
        uuid = uuidv4().replaceAll('-','');
        window.localStorage.setItem(UUID_KEY, uuid);
    }
};
export const getUuid = () => {
    let uuid = window.localStorage.getItem(UUID_KEY);
    if(!uuid){
        uuid = uuidv4().replaceAll('-','');
        window.localStorage.setItem(UUID_KEY, uuid);
    }
    return uuid;
};
export const authVerify = () => {
    let result = true;

    let res = request('POST', process.env.VUE_APP_API_URL+'/admin/auth/verify', {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': JwtService.getToken(),
            'zhi-uuid': getUuid()
        },
    });
    if(res.statusCode == 401){
        result = false;
    }else{
        window.ZHI_TOKEN = JwtService.getToken();
    }
    return result;
}
export const initAccountInfo = () => {
    let res = request('POST', process.env.VUE_APP_API_URL+'/admin/account/info', {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': JwtService.getToken(),
            'zhi-uuid': getUuid()
        },
    });
    if(res.statusCode == 200){
        let data = JSON.parse(res.body)
        if(data['flag'] && data['flag'] == 'yes'){
            if(data['menuInfo']){
                store.commit(Mutations.SET_ACCOUNT_MENU, data['menuInfo']);
            }
            if(['/admin', '/'].includes(window.location.pathname)){
                router.push({
                    path: '/admin/report/report/list'
                })
            }
        }
    }
    console.log('get account info finished')

    // ApiService.post("admin/account/info", {})
    //     .then(({ data }) => {
    //         if(data['flag'] && data['flag'] == 'yes'){
    //             if(data['menuInfo']){
    //                 store.commit(Mutations.SET_ACCOUNT_MENU, data['menuInfo']);
    //             }
    //         }
    //     }).catch((e) => { console.log(e); });
}
export const saveAccountInfo = (accountInfo) => {
    window.localStorage.setItem('account_info', JSON.stringify(accountInfo));
}
export const getAccountInfo = () => {
    let accountInfo = window.localStorage.getItem('account_info');
    return accountInfo ? JSON.parse(accountInfo) : {
        name: '',
        nickname: ''
    };
}
export const destroyAccountInfo = () => {
    store.state.body.accountInited = false;
    window.localStorage.removeItem('account_info');
}


const { toClipboard } = useClipboard()
export const copy = async(text) => {
    try {
        await toClipboard(text);
    } catch (e) {
        console.error(e)
    }
}
export const downloadImage = (imageSrc, name) => {
    let image = new Image()  //创建图片对象
    image.setAttribute('crossOrigin', 'anonymous') //设置允许跨域
    image.src = imageSrc+"?"+Date.now() //赋值src
    image.onload = () => {
        //等待图片加载完成创建canvas
        let canvas = document.createElement('canvas')
        //将图片绘制到canvas画布上
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        //获取图片文件格式  jpg/jpeg/png
        let ext = image.src.substring(image.src.lastIndexOf('.')+1).toLowerCase()
        //导出画布快照  此时已越过跨域限制成功取到图片数据
        let dataURL = canvas.toDataURL('image/' + ext)
        //创建a标签
        const a = document.createElement("a");
        // 赋值a标签download属性值，标明该a标签提供下载功能，同时该属性指定了下载时的文件名称
        a.download = name;
        //赋值a标签下载地址，即canvas导出的画布快照 base64格式的图片地址
        a.href = dataURL;
        //添加a标签到dom中
        document.body.appendChild(a);
        //触发a标签点击事件 触发下载
        a.click();
        //下载完成 删除多余的a标签  保持页面原有元素不动
        document.body.removeChild(a);
    }
}
export const previewImage = (src) => {
    previewImageList([src]);
}
export const previewImageList = (srcList) => {
    let proxy = window.vueApp['_instance']['proxy'];
    proxy.$viewerApi({
        options: {
            toolbar: true,
            title: false,
            tooltip: false
        },
        images: srcList
    });
}
export const inputDigital = (item) => {
    item = item.replace(/^(0+)|[^\d]+/g,'');
    return item;
}
export const inputRange = (item, min, max) => {
    let t = Number(item);
    if(t < min){
        t = min;
    }
    if(t > max){
        t = max;
    }
    return t;
}
export const exportExcel = (excelData) => {
    let headers = excelData.headers;
    let rows = excelData.rows;
    let sheetName = excelData['sheetName'] ? excelData['sheetName'] : '默认表单';
    let fileName = excelData['fileName'] ? excelData['fileName'] : '默认文件名';

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    /* fix headers */
    XLSX.utils.sheet_add_aoa(worksheet, [headers.map((item) => { return item.name })], { origin: "A1" });

    worksheet["!cols"] = headers.map((item) => { return { wch : item.width}; });
    for(let i=0;i<headers.length;i++){
        let cell = XLSX.utils.encode_col(i)+1;
        worksheet[cell].s = {
            font: {
                sz: 10,
                bold: true,
                color: { rgb: "434343" },
            },
            fill: {
                fgColor: { rgb: "8DB4E2" }
            }
        };
    }
    for(let i=0;i<headers.length;i++){
        for(let j=0;j<rows.length;j++){
            let cell = XLSX.utils.encode_col(i)+(j+2);
            worksheet[cell].s = {
                font: {
                    sz: 10,
                    color: { rgb: "333333" },
                },
                alignment:{
                    vertical: true,
                    wrapText: true
                }
            };
        }
    }


    /* calculate column width */
    // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    // worksheet["!cols"] = [ { wch: max_width } ];

    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, fileName+".xlsx");
}

let blockLoading = null;
export const blockPage = (text='处理中，请稍等...') => {
    blockLoading = ElLoading.service({
        lock: true,
        text: text,
        background: 'rgba(0, 0, 0, 0.7)',
    })
}
export const unblockPage = () => {
    blockLoading ? blockLoading.close(): null;
}

export const navToPage = (path) => {
    if(path){
        router.push({
            path: path
        })
    }
}
export const downloadFile = (url, name) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    let file = url;
    let headers = new Headers();
    headers.append('Authorization', JwtService.getToken());
    headers.append('Zhi-UUID', getUuid());
    fetch(file, { headers })
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);
            anchor.href = objectUrl;
            anchor.download = name;
            anchor.click();
            window.URL.revokeObjectURL(objectUrl);
        });
}
export function randomString(length) {
    let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = [];
    for (let i = length; i > 0; --i)
        result.push(str[Math.floor(Math.random() * str.length)]);
    return result.join('');
}

const mdi = new MarkdownIt({
    breaks: true
})
export const getMdiText = (text) => {
    // console.log('begin to render: '+ text)
    return mdi.render(text)
}

export default {
    checkAndGenerateUuid,
    getUuid,
    authVerify,
    initAccountInfo,
    saveAccountInfo,
    getAccountInfo,
    destroyAccountInfo,
    copy,
    downloadImage,
    previewImage,
    previewImageList,
    inputDigital,
    inputRange,
    exportExcel,
    blockPage,
    unblockPage,
    navToPage,
    downloadFile,
    randomString,
    getMdiText
};
