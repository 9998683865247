import { createApp } from 'vue'
import App from './App.vue'

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import VueViewer from 'v-viewer'

import ApiService from "@/core/services/ApiService";
import "bootstrap";
import InlineSvg from "vue-inline-svg";
import NormalTable from "@/components/table/NormalTable.vue";
import ImageUpload from "@/components/file/ImageUpload.vue";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus,{
    locale: zhCn
});
app.use(VueViewer);

ApiService.init(app);
app.component("inline-svg", InlineSvg);
app.component('normal-table',NormalTable);
app.component('image-upload',ImageUpload);

window.vueApp = app;
app.mount('#app');

export default app;
