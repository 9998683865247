export const subMenuRouteList = [
];

export const routeMap = {
  '/admin': 'admin.dashboard',
  '/admin/aihub': 'admin.aihub',
  '/admin/aihub/chatgpt': 'admin.aihub',
  '/admin/account/account/list': 'admin.account.account',
  '/admin/account/account/new': 'admin.account.account',
  '/admin/account/role/list': 'admin.account.role',
  '/admin/account/role/new': 'admin.account.role',
  '/admin/basic/acl/config': 'admin.basic.acl',
  '/admin/basic/acl/import': 'admin.basic.acl',

  '/admin/report/report/list': 'admin.report.report',
  '/admin/report/report/edit': 'admin.report.report',
  '/admin/report/report/generate': 'admin.report.generate',
  '/admin/report/report/generate2': 'admin.report.generate',
  '/admin/report/category/list': 'admin.report.category',

  '/admin/knowledge/list': 'admin.knowledge.list',
  '/admin/knowledge/list2': 'admin.knowledge.list',
  '/admin/knowledge/view': 'admin.knowledge.list',
  '/admin/knowledge/unit/create': 'admin.knowledge.list',
  '/admin/knowledge/unit/view': 'admin.knowledge.list',

  '/admin/statistic/report': 'admin.statistic.report',
  '/admin/statistic/report2': 'admin.statistic.report',
  '/admin/statistic/bill': 'admin.statistic.bill',

  '/admin/chat/basic': 'admin.chat.basic',
}

export const routeWhiteList = [
  '/404',
  '/admin/account/info',
];

export default {
  subMenuRouteList,
  routeWhiteList
};