export default [
    {
        path: "/admin/aihub",
        name: "aihub-dashboard",
        component: () => import("@/views/aihub/dashboard.vue"),
    },
    {
        path: "/admin/aihub/chatgpt",
        name: "aihub-chatgpt",
        component: () => import("@/views/aihub/chatgpt.vue"),
    },
]