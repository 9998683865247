const Actions = {
  // action types
  ADD_BODY_CLASSNAME : "addBodyClassName",
  REMOVE_BODY_CLASSNAME : "removeBodyClassName",
  VERIFY_AUTH : "verifyAuth",
  LOGIN : "login",
  LOGOUT : "logout",
  REGISTER : "register",
  UPDATE_USER : "updateUser",
  FORGOT_PASSWORD : "forgotPassword",
  GET_SUB_MENU: 'getSubMenu',
  SET_MENU_ASIDE_MINIMIZE: 'setMenuAsideMinimize',
  CAL_CONTAINER_HEIGHT: 'calContainerHeight'
};

const Mutations = {
  // mutation types
  PURGE_AUTH : "logOut",
  SET_AUTH : "setAuth",
  SET_USER : "setUser",
  SET_AVATAR_NUMBER : "setAvatarNumber",
  SET_PASSWORD : "setPassword",
  SET_ERROR : "setError",
  SET_BREADCRUMB : "setBreadcrumb",
  SET_BREADCRUMB_SHOW : "setBreadcrumbShow",
  SET_CURRENT_PATH : "setCurrentPath",
  SET_ACCOUNT_RESOURCE : "setAccountResource",
  SET_ASIDE_MENU: 'setAsideMenu',
  SET_ASIDE_MENU_LIST: 'setAsideMenuList',
  SET_ASIDE_MENU_ACTIVE_INDEX: 'setasideMenuActiveIndex',
  SET_TOP_MENU: 'setTopMenu',
  SET_ROUTE_MAP: 'setRouteMap',
  SET_SUB_MENU_MAP: 'setSubMenuMap',
  SET_SUB_MENU: 'setSubMenu',
  SET_CURRENT_ROUTE: 'setCurrentRoute',
  SET_ASIDE_MINIMIZE: 'setAsideMinimize',
  SET_MODULE: 'setModule',
  SET_ACCOUNT_MENU : "setAccountMenu",
};

export { Actions, Mutations };
