export default [
    {
        path: "/admin/report/report/list",
        name: "report-report-list",
        component: () => import("@/views/report/list.vue"),
    },
    {
        path: "/admin/report/report/edit",
        name: "report-report-edit",
        component: () => import("@/views/report/md_edit.vue"),
    },
    {
        path: "/admin/report/report/edit2",
        name: "report-report-edit2",
        component: () => import("@/views/report/edit.vue"),
    },
    {
        path: "/admin/report/report/generate",
        name: "report-report-generate",
        component: () => import("@/views/report/generate.vue"),
    },
    {
        path: "/admin/report/report/generate2",
        name: "report-report-generate2",
        component: () => import("@/views/report/generate2.vue"),
    },
    {
        path: "/admin/report/category/list",
        name: "report-category-list",
        component: () => import("@/views/report/category.vue"),
    },
]