export default [
    {
        path: "/admin/data/db/backup",
        name: "data-db-backup",
        component: () => import("@/views/data/db/backup.vue"),
    },
    {
        path: "/admin/data/chroma/view",
        name: "data-chroma-view",
        component: () => import("@/views/data/chroma/view.vue"),
    }
]