<template>
  <div class="d-flex flex-column">
    <div class="search-content" v-if="tableOptions.hasSearch">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" @submit.prevent>
        <slot name="search"></slot>
        <el-form-item>
          <el-button color="#e4e6ef" class="fs-12px" @click="searchClick">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="info-content">
      <slot name="info"></slot>
    </div>
    <div class="table-content">
      <el-table v-loading="tableOptions.loading"
                element-loading-text="正在加载中..."
                element-loading-background="rgba(168, 168, 168, 0.1)"
                :data="tableOptions.rowData"
                :stripe="tableOptions.stripe"
                :border="tableOptions.border"
                header-row-class-name="table-header-blue"
                :height="tableOptions.height"
                :max-height="tableOptions.maxHeight"
                :cell-style="tableOptions.cellStyle"
                :header-cell-style="tableOptions.headCellStyle"
                :style="tableOptions.style"
                class="zhi-table table-nohover"
                :ref="tableOptions.ref ? tableOptions.ref : 'elTableRef'"
                :highlight-current-row="tableOptions.highLightCurrentRow ? tableOptions.highLightCurrentRow : false"
                @current-change="handleCurrentChange"
      >
        <slot name="column"></slot>
      </el-table>
      <div class="d-flex justify-content-end" style="margin-top: 10px;" v-if="tableOptions.hasPagination">
        <el-pagination
            v-model:currentPage="tableOptions.currentPage"
            v-model:page-size="tableOptions.pageSize"
            :page-sizes="tableOptions.pageSizeList"
            :small="true"
            :disabled="false"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableOptions.total"
            @size-change="handlePaginationSizeChange"
            @current-change="handlePaginationCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    tableOptions: Object,
    searchForm: Object
  },
  emits: ['fetch-data'],
  data() {
    return {
      params: {
      },
      currentRow: null
    }
  },
  mounted:function(){
    this.fetchTableData();
  },
  computed: {
  },
  methods: {
    fetchTableData: function(params = {}){
      this.$emit('fetch-data',params);
    },
    searchClick: function(){
      this.fetchTableData();
    },
    handlePaginationSizeChange : function(pageSize){
      this.fetchTableData({
        'pageSize': pageSize,
        'currentPage': 1
      });
    },
    handlePaginationCurrentChange : function(currentPage){
      this.fetchTableData({
        'currentPage': currentPage
      });
    },
    handleCurrentChange: function(currentRow, oldCurrentRow){
      this.currentRow = currentRow;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

