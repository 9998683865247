import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import adminRouter from "./modules/admin";
import aihubRouter from "./modules/aihub";
import reportRouter from "./modules/report";
import knowledgeRouter from "./modules/knowledge";
import agentRouter from "./modules/agent";
import statisticRouter from "./modules/statistic";
import dataRouter from "./modules/data";
import toolboxRouter from "./modules/toolbox";
import chatRouter from "./modules/chat";
import {routeMap, subMenuRouteList} from "@/core/data/ConstData";

const routes = [
    {
        path: "/",
        redirect: "/admin",
        component: () => import("@/layout/Layout.vue"),
        children: [
            ...adminRouter,
            ...aihubRouter,
            ...reportRouter,
            ...knowledgeRouter,
            ...agentRouter,
            ...statisticRouter,
            ...dataRouter,
            ...toolboxRouter,
            ...chatRouter,
        ]
    },
    {
        path: "/auth/login",
        name: "sign-in",
        component: () => import("@/views/auth/SignIn.vue"),
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/error/Error404.vue"),
    },
    {
        path: "/500",
        name: "500",
        component: () => import("@/views/error/Error500.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to,from) => {
    // store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

router.afterEach((to, from, failure) => {
    let toPathList = to.path.split('/');
    let toModule = 'admin';
    if(toPathList.length > 1){
        toModule = toPathList[1];
    }

    // ZhiService.gateKeeper();

    let fromPathList = from.path.split('/');
    let fromModule = 'admin';
    if(fromPathList.length > 1){
        fromModule = fromPathList[1];
    }
    console.log('router after: from ',from.fullPath, ' to ', to.fullPath);

    if(toModule != fromModule){
        store.commit(Mutations.SET_MODULE, toModule);
        // let menuList = store.getters.asideMenuList;
        // if(menuList){
        //     let sideMenu = menuList[toModule];
        //     store.commit(Mutations.SET_ASIDE_MENU, sideMenu);
        // }
    }
    store.commit(Mutations.SET_CURRENT_ROUTE, to.path);
    if(routeMap[to.path]){
        if(subMenuRouteList.includes(to.path)){
            store.dispatch(Actions.GET_SUB_MENU, routeMap[to.path]);
            store.dispatch(Actions.SET_MENU_ASIDE_MINIMIZE, true);
        }else{
            store.dispatch(Actions.SET_MENU_ASIDE_MINIMIZE, false);
        }
        store.commit(Mutations.SET_ASIDE_MENU_ACTIVE_INDEX, routeMap[to.path]);
    }
})

export default router;