export default [
    {
        path: "/admin",
        name: "admin-dashboard",
        component: () => import("@/views/Dashboard.vue"),
    },
    {
        path: "/admin/account/info",
        name: "admin-account-info",
        component: () => import("@/views/account/AccountInfo.vue"),
    },
    {
        path: "/admin/account/account/list",
        name: "account-account-list",
        component: () => import("@/views/account/account/AccountList.vue"),
    },
    {
        path: "/admin/account/account/new",
        name: "account-account-new",
        component: () => import("@/views/account/account/AccountNew.vue"),
    },
    {
        path: "/admin/account/role/list",
        name: "account-role-list",
        component: () => import("@/views/account/role/RoleList.vue"),
    },
    {
        path: "/admin/account/role/new",
        name: "account-role-new",
        component: () => import("@/views/account/role/RoleNew.vue"),
    },
    {
        path: "/admin/basic/acl/config",
        name: "basic-acl-config",
        component: () => import("@/views/basic/acl/AclConfig.vue"),
    },
    {
        path: "/admin/basic/acl/import",
        name: "basic-acl-import",
        component: () => import("@/views/basic/acl/AclImport.vue"),
    },
]