export default [
    {
        path: "/admin/knowledge/list",
        name: "knowledge-list",
        component: () => import("@/views/knowledge/list.vue"),
    },
    {
        path: "/admin/knowledge/list2",
        name: "knowledge-list2",
        component: () => import("@/views/knowledge/list2.vue"),
    },
    {
        path: "/admin/knowledge/view",
        name: "knowledge-view",
        component: () => import("@/views/knowledge/view.vue"),
    },
    {
        path: "/admin/knowledge/unit/create",
        name: "knowledge-unit-create",
        component: () => import("@/views/knowledge/unit/create.vue"),
    },
    {
        path: "/admin/knowledge/unit/view",
        name: "knowledge-unit-view",
        component: () => import("@/views/knowledge/unit/view.vue"),
    },
]