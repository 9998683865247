import axios from "axios";
import VueAxios from "vue-axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import JwtService from "@/core/services/JwtService";
import ZhiService from "@/core/services/ZhiService";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    static vueInstance;
 
    /**
     * @description initialize vue axios
     */
    static init(app) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
        ApiService.vueInstance.axios.interceptors.request.use(
            (config) => {
                const token = JwtService.getToken();
                if (token) {
                    config.headers["Authorization"] = token;
                    config.headers["Zhi-UUID"] = ZhiService.getUuid();
                }
                config.headers["Accept"] = "application/json";
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        ApiService.vueInstance.axios.interceptors.response.use(function (response) {
            ZhiService.unblockPage();
            return response;
        }, function (error) {
            ZhiService.unblockPage();
            if(error.response.status == 401){
                // store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "sign-in" }));
                return error.response;
            }
            console.log('axios error: ',error);
            return Promise.reject(error);
        });
    }

    /**
     * @description set the default HTTP request headers
     */
    static setHeader() {
        // ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = JwtService.getToken();
        ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
            "application/json";
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    static query(
        resource,
        params
    ) {
        return ApiService.vueInstance.axios.get(resource, params);
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    static get(
        resource,
        slug = ""
    ) {
        return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    static post(
        resource,
        params,
        config = {}
    ) {
        return ApiService.vueInstance.axios.post(`${resource}`, params,config);
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    static update(
        resource,
        slug,
        params
    ) {
        return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    static put(
        resource,
        params
    ) {
        return ApiService.vueInstance.axios.put(`${resource}`, params);
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    static delete(resource) {
        return ApiService.vueInstance.axios.delete(resource);
    }
}

export default ApiService;
