import { createStore } from "vuex";

import auth from "@/store/modules/auth";
import body from "@/store/modules/body";

const store = createStore({
  modules: {
    auth: auth,
    body: body
  },
});

export default store;
