export default [
    {
        path: "/admin/statistic/report",
        name: "statistic-report",
        component: () => import("@/views/statistic/report.vue"),
    },
    {
        path: "/admin/statistic/report2",
        name: "statistic-report2",
        component: () => import("@/views/statistic/report2.vue"),
    },
    {
        path: "/admin/statistic/bill",
        name: "statistic-bill",
        component: () => import("@/views/statistic/bill.vue"),
    },
]